<template>
    <b-row>
         <b-col cols="12">
              <!-- <b-card-code title="Basic Table"> -->
                <!-- table -->

                <!-- search input -->
                <div class="custom-search d-flex justify-content-end">
                <b-form-group>
                    <div class="d-flex align-items-center">
                    <label class="mr-1">Search</label>
                    <b-form-input
                        v-model="searchTerm"
                        placeholder="Search"
                        type="text"
                        class="d-inline-block"
                        v-on:keyup.enter="getCompanies"
                    />
                    </div>
                </b-form-group>
                </div>
    
              <vue-good-table
                :columns="columns"
                :rows="rows"
                
                :pagination-options="{
                    enabled: true,
                    perPage:pageLength
                }"
                @on-sort-change="onSortChange"
                >
                <template
                    slot="table-row"
                    slot-scope="props"
                >

                    <!-- Column: Name -->
                    <span
                      v-if="props.column.field === 'name'"
                      class="text-nowrap"
                      >
                      <b-avatar
                          :src="props.row.avatar"
                          class="mx-1"
                      />
                      <!-- <span class="text-nowrap">{{ props.row.name }}</span> --> 
                    </span>

                    <!-- Column: Status -->
                    <!-- <span v-else-if="props.column.field === 'status'">
                    <b-badge :variant="statusVariant(props.row.status)">
                        {{ props.row.status }}
                    </b-badge>
                    </span> -->

                    <span
                    v-if="props.column.field === 'phone'"
                    class="text-nowrap"
                    >
                      {{ getFormattedNumber(props.row.phone) }}
                    </span>

                    <!-- Column: Action -->
                    <span v-else-if="props.column.field === 'action'">
                    <span>
                        <b-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                        >
                        <template v-slot:button-content>
                            <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="text-body align-middle mr-25"
                            />
                        </template>
                      
                        <b-dropdown-item :href="getEditLink(props.row._id)">
                            <feather-icon
                            icon="Edit2Icon"
                            class="mr-50"
                            />
                            <span>Edit</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="deleteCompany(props.row)">
                            <feather-icon
                            icon="TrashIcon"
                            class="mr-50"
                            />
                            <span>Delete</span>
                        </b-dropdown-item>
                        </b-dropdown>
                    </span>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                    </span>
              </template>

                <!-- pagination -->
              <template
                    slot="pagination-bottom"
                    slot-scope="props"
              >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                          <span class="text-nowrap ">
                          Showing 1 to
                          </span>
                          <b-form-select
                          v-model="pageLength"
                          :options="['5','10','15','20']"
                          class="mx-1"
                          @input="(value)=>props.perPageChanged({currentPerPage:value})"
                          />
                          <span class="text-nowrap"> of {{ props.total }} entries </span>
                      </div>
                      <div>
                          <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="pageLength"
                          first-number
                          last-number
                          align="right"
                          prev-class="prev-item"
                          next-class="next-item"
                          class="mt-1 mb-0"
                          >
                          <!-- <template #prev-text>
                              <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                              />
                          </template>
                          <template #next-text>
                              <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                              />
                          </template> -->
                          </b-pagination>
                      </div>
                    </div>
              </template>
            </vue-good-table>
            <!-- </b-card-code> -->
        </b-col>
    </b-row>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BRow, BCol, BCard, BCardBody
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import AxiosService from '@/Service/AxiosService'
// import { retrieveColumnLayout } from 'echarts/lib/layout/barGrid'
// import { runInThisContext } from 'vm'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    // BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Company Name',
          field: 'name',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Phone',
          field: 'phone',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      totalRows:1,
      searchTerm: '',
      currentPage:1,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  created() {
    this.getCompanies()
  },
  methods:{
    getCompanies(){
      this.$store.dispatch("companies", {
          pageLength:this.pageLength,
          searchTerm:this.searchTerm,
          currentPage:this.currentPage,
      })

      // AxiosService.get("/parent-companies", {
      //     pageLength:this.pageLength,
      //     searchTerm:this.searchTerm,
      //     currentPage:this.currentPage,
      //     type:"parent"
      //   }).then(({error, message, data})=>{
        
      //     if(!data) 
      //     return;
        
      //     if(data.rows) this.rows = data.rows;
      //     if(data.totalRows) this.totalRows = data.totalRows;
      // })

    },
    deleteCompany(company){
      this.$swal({
        title: 'Warning!',
        text: `Are you sure you want to delete ${company.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Delete ${company.name}`,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      }).then(result => {
        // console.log("result", result)
        if(!result.isConfirmed) return;
        
        AxiosService.post("/deleteCompany", {id:company._id}).then(({error, data})=>{
          if(error) console.log("error");
          else console.log("deleted");

          this.getCompanies()
        })
      })
    },
    onSortChange(params) {
      console.log(`the user ordered:`, params[0])
    },
    getEditLink(id){
      return `/company-edit?id=${id}`
    },
    getFormattedNumber(number){
      // console.log("number", number)
      let cleaned = ('' + number).replace(/\D/g, '');
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        // console.log("result", '(' + match[1] + ') ' + match[2] + '-' + match[3])
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
      return null;
    }
  },
  computed:{
    company(){
      return this.$store.getters.getCompany || {};
    }
  },
  watch:{
    company(val){
      this.rows = val.rows;
      this.totalRows = val.totalRows;
    }
  }
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '~@core/scss/vue/libs/vue-sweetalert.scss';
</style>